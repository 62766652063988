import { IConfiguration } from './IConfiguration'

class ConfigService {
  private configuration: IConfiguration

  constructor() {
    const runtimeConfiguration = (window as any).APP_CONFIGURATION

    const compiletimeConfiguration: IConfiguration = {
      REACT_APP_API_URL: process.env.REACT_APP_API_URL || '',
    }
    this.configuration = this.isValidRunTimeConfig(runtimeConfiguration)
      ? runtimeConfiguration
      : compiletimeConfiguration
  }

  set compiletimeConfiguration(configuration: IConfiguration) {
    this.configuration = {
      ...this.configuration,
      ...configuration,
    }
  }

  get config(): IConfiguration {
    return {
      ...this.configuration,
    }
  }

  private isValidRunTimeConfig = (c: IConfiguration) => c && c.REACT_APP_API_URL
}

export default new ConfigService()
